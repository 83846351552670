import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import {
  DatePickerField,
  ReactSelectField,
  InputField,
  SwitchField,
  Render,
  FieldMessage
} from '@oforce/global-components';
import { useActiveAccount } from '../../../shared/activeAccount';

import { FormSection } from '../../../components';

export default function PolicyDetailsFormFields({ formik, formOptions }) {
  const { isAdmin } = useActiveAccount();

  return (
    <FormSection title="Policy Details">
      {/* Policy specific field */}
      <InputField name="policyNumber" label="Policy Number" placeholder="12345" />
      {/* Shared fields */}
      <InputField name="name" label="Name" />
      <InputField name="externalId" label="External Id" />
      <Render if={formik?.values?.showRetailBrokerField}>
        <ReactSelectField
          name="retailBroker"
          label="Retail Broker"
          options={formOptions?.companies?.options}
          isLoading={formOptions?.companies?.loading}
          isClearable
          placeholder="Select a Retail Broker..."
        />
      </Render>
      <ReactSelectField
        className="mb-3"
        name="insuranceType"
        id="insuranceType"
        label="Insurance Type"
        isClearable
        required
        options={formOptions?.insuranceTypes?.options}
        isLoading={formOptions?.insuranceTypes?.loading}
        isDisabled
        placeholder="Select type..."
      />
      <ReactSelectField
        name="insuranceCarrier"
        label="Insurance Carrier"
        options={formOptions?.companies?.options}
        isLoading={formOptions?.companies?.loading}
        isClearable
        placeholder="Select an Insurance Carrier..."
      />
      <SwitchField
        id="enableCustomStartDates"
        className="mb-3"
        label="Custom Start Dates"
        name="enableCustomStartDates"
        optional
        size="sm"
        switchLabel={formik.values.enableCustomStartDates ? 'Enabled' : 'Disabled'}
      />
      <Row form>
        <Col>
          <InputField
            name="startDateBackdatingLimit"
            label="Backdate Start Limit"
            type="number"
            messageAfter="Days"
            disabled={!formik.values.enableCustomStartDates}
            fullWidth
          />
        </Col>
        <SwitchField
          id="enableCustomTerminationDates"
          className="mb-3"
          label="Custom Termination Dates"
          name="enableCustomTerminationDates"
          optional
          size="sm"
          switchLabel={formik?.values?.enableCustomTerminationDates ? 'Enabled' : 'Disabled'}
        />
        <Col>
          <InputField
            name="terminationDateBackdatingLimit"
            label="Backdate Termination Limit"
            type="number"
            messageAfter="Days"
            disabled={!formik?.values?.enableCustomTerminationDates}
            fullWidth
          />
        </Col>
      </Row>
      {/* Policy specific fields */}
      <ReactSelectField
        options={formOptions?.policyStatus?.options}
        isDisabled={formik?.values?.disableStatusField}
        name="status"
        label="Status"
        required
        placeholder="Select a status..."
      />
      <ReactSelectField
        options={formOptions?.states?.options}
        name="state"
        label="State"
        isClearable
        placeholder="Select a state..."
      />
      <Row form className="mb-4">
        <Col>
          <DatePickerField
            allowClear
            name="effectiveDate"
            label="Effective Date"
            format="MM/DD/YYYY"
            inputReadOnly={true}
            disabled={formik?.values?.hasInvoiced}
            messageAfter={formik?.values?.hasInvoiced && 'Cannot be changed if policy has been invoiced.'}
            messageAfterProps={{ icon: ['far', 'info-circle'] }}
            fullWidth
          />
          <FieldMessage className="w-100" icon={['far', 'info-circle']}>
            Changing or Adding this date may adjust some exposure election start dates under this policy as an exposure
            election's start date can't be before the policy's effective date.
          </FieldMessage>
        </Col>
        <Col>
          <DatePickerField
            name="expirationDate"
            label="Expiration Date"
            format="MM/DD/YYYY"
            inputReadOnly={true}
            allowClear
            disabledDate={current => current && current <= moment()}
            fullWidth
          />
        </Col>
      </Row>
      <ReactSelectField
        className="mb-3"
        name="ratingZoneSource"
        id="ratingZoneSource"
        label="Rating Zone Source"
        isClearable
        required
        options={formOptions?.ratingZoneSource?.options}
        isLoading={formOptions?.ratingZoneSource?.loading}
        placeholder="Select rating zone source..."
      />
      <Render if={isAdmin}>
        <SwitchField
          id="apiOnlyExposureUpdates"
          className="mb-3"
          label="API Only Exposure Updates"
          name="apiOnlyExposureUpdates"
          optional
          size="sm"
          switchLabel={formik.values.apiOnlyExposureUpdates ? 'Enabled' : 'Disabled'}
        />
      </Render>
      <Render if={isAdmin}>
        <InputField name="netSuiteId" label="NetSuite ID" type="text" fullWidth />
      </Render>
    </FormSection>
  );
}
