import moment from 'moment';
import { gql, useMutation } from '@apollo/client';

import {
  POLICY_CORE_FIELDS,
  RETAIL_BROKER_FIELDS,
  INSURANCE_CARRIER_FIELDS
} from '../../../views/policy/hooks/usePolicy';
import { useProrateSettings } from '../../pricing/hooks';

const UPDATE_POLICY = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ...PolicyCoreFields
      ...InsuranceCarrierFields
      ...RetailBrokerFields
    }
  }
  ${POLICY_CORE_FIELDS}
  ${INSURANCE_CARRIER_FIELDS}
  ${RETAIL_BROKER_FIELDS}
`;

export default function usePolicyFormSubmit({
  policy,
  onCompleted,
  toggleConfirmModal,
  confirmed,
  onError = () => {}
}) {
  const [update, { loading, error }] = useMutation(UPDATE_POLICY, {
    onCompleted,
    onError
  });

  const {
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();

  const formSubmit = ({
    retailBroker,
    insuranceCarrier,
    insuranceType,
    pricingRateType,
    state,
    status,
    pricingIsExternallyFactored,
    prorateSetting,
    enableProrating,
    disableStatusField,
    showRetailBrokerField,
    hasInvoiced,
    invoiceDueDay,
    billingRule,
    customData,
    ratingZoneSource,
    ...values
  }) => {
    const effectiveDateHasPassed = moment(values?.effectiveDate).format('MM-DD-YYYY') < moment().format('MM-DD-YYYY');
    const automateInvoiceGeneration = values?.automateInvoiceGeneration;

    if (effectiveDateHasPassed && automateInvoiceGeneration && !confirmed) {
      toggleConfirmModal();
    } else {
      const invoiceGenerationDay = !!values?.invoiceGenerationDay ? Number(values?.invoiceGenerationDay) : null;

      const input = {
        ...values,
        customData: JSON.stringify(customData),
        pricingRateType: pricingRateType?.value,
        retailBrokerCompanyId: retailBroker?.value,
        insuranceCarrierCompanyId: insuranceCarrier?.value,
        billingRule: billingRule?.value,
        ratingZoneSource: ratingZoneSource.value,

        status: ['ACTIVE', 'EXPIRED'].includes(status?.value) ? policy?.status : status?.value,
        state: state?.value,
        effectiveDate: values?.effectiveDate ? moment(values?.effectiveDate).format(moment.HTML5_FMT.DATE) : null,
        expirationDate: values?.expirationDate ? moment(values?.expirationDate).format(moment.HTML5_FMT.DATE) : null,

        invoiceGenerationDay,
        invoicingSchedule: values?.invoicingSchedule?.value,
        prorateSetting: enableProrating ? prorateSetting?.value : NO_PRORATE,
        invoiceDueDay: invoiceDueDay === '' ? null : parseInt(invoiceDueDay)
      };

      update({
        variables: { input }
      });
    }
  };

  return { formSubmit, loading, error };
}
