import * as yup from 'yup';

import { useActiveAccount } from '../../../shared/activeAccount';
import { backendNameToLabel } from '../../../utils/formatting';
import { useProrateSettings } from '../../pricing/hooks';
import { guardLimit } from '../../exposureElections/hooks';
import { billingRuleErrorMessage, isBillingRuleAllowed, SNAPSHOT_CUSTOM_DAY } from '../../product/utils';
import { toHeaderCase } from 'js-convert-case';

export default function usePolicyFormSteps({ policy, formOptions }) {
  const {
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();
  const {
    roleIsAtLeast,
    ROLES: { POLICY_MANAGER }
  } = useActiveAccount();

  const formSteps = [
    {
      name: 'Policy Details',
      initialValues: {
        id: policy?.id,
        policyNumber: policy?.policyNumber || '',
        name: policy?.name,
        externalId: policy?.externalId || '',
        retailBroker:
          formOptions?.companies?.options?.find(option => option.value === policy?.retailBroker?.companyId) || null,
        insuranceType:
          formOptions?.insuranceType?.options?.find(option => option.value === policy?.insuranceType?.id) || null,
        insuranceCarrier:
          formOptions?.companies?.options?.find(option => option.value === policy?.insuranceCarrier?.companyId) || null,
        enableCustomStartDates: !!policy?.enableCustomStartDates ? policy?.enableCustomStartDates : false,
        enableCustomTerminationDates: !!policy?.enableCustomTerminationDates ? policy?.enableCustomTerminationDates : false,
        status: policy?.effectiveStatus
          ? formOptions?.policyStatus?.options.find(x => x.value === policy?.effectiveStatus) || {
              value: policy?.effectiveStatus,
              label: backendNameToLabel(policy?.effectiveStatus)
            }
          : formOptions?.policyStatus?.options[0],
        state: formOptions?.states?.options?.find(x => x.value === policy?.state) || null,
        effectiveDate: policy?.effectiveDate || '',
        expirationDate: policy?.expirationDate || '',
        startDateBackdatingLimit: guardLimit(policy?.startDateBackdatingLimit),
        terminationDateBackdatingLimit: guardLimit(policy?.terminationDateBackdatingLimit),

        disableStatusField: policy?.effectiveStatus === 'ACTIVE' || policy?.effectiveStatus === 'EXPIRED',
        showRetailBrokerField: roleIsAtLeast(POLICY_MANAGER),
        apiOnlyExposureUpdates: !!policy?.apiOnlyExposureUpdates ? true : false,
        backdate: !!policy?.backdate ? true : false,
        ratingZoneSource: policy?.ratingZoneSource
          ? {
              label: toHeaderCase(policy?.ratingZoneSource),
              value: policy?.ratingZoneSource
            }
          : formOptions?.ratingZoneSource?.options[0],
          netSuiteId: policy?.netSuiteId || ''
      },
      validationSchema: yup.object().shape({
        id: yup.string().required('Required'),
        policyNumber: yup.string(),
        name: yup.string().required('Required'),
        externalId: yup.string(),
        retailBroker: yup.object().nullable(),
        insuranceType: yup.object().nullable().required('Required'),
        insuranceCarrier: yup.object().nullable(),
        enableCustomStartDates: yup.bool().required('Required'),
        enableCustomTerminationDates: yup.bool().required('Required'),
        status: yup.object().nullable().required('Required'),
        state: yup.object().nullable(),
        effectiveDate: yup.string().when('automateInvoiceGeneration', {
          is: true,
          then: yup.string().required('Required'),
          otherwise: yup.string()
        }),
        expirationDate: yup.string(),
        startDateBackdatingLimit: yup.number().required('Required'),
        terminationDateBackdatingLimit: yup.number().required('Required'),
        apiOnlyExposureUpdates: yup.bool().required('Required'),
        backdate: yup.bool(),
        ratingZoneSource: yup.object().required('Required')
      })
    },
    {
      name: 'Policy Custom Data',
      initialValues: {
        customData: policy?.customData || {}
      },
      validationSchema: yup.object().shape({
        customData: yup.object()
      })
    },
    {
      name: 'Policy Pricing',
      initialValues: {
        pricingRateType:
          formOptions?.pricingRate?.options?.find(type => type.value === policy?.pricingRateType) || null,
        enableProrating: !!policy?.prorateSetting ? policy?.prorateSetting !== NO_PRORATE : false,
        prorateSetting: formOptions?.prorateSettings?.options?.find(x => x.value === policy?.prorateSetting) || null,
        prorateAddons: policy?.prorateAddons,
        hasInvoiced: !!policy?.latestInvoice?.id
      },
      validationSchema: yup.object().shape({
        pricingRateType: yup.object().nullable().required('Required'),
        enableProrating: yup.bool(),
        prorateSetting: yup.mixed().when('enableProrating', {
          is: true,
          then: yup.object().nullable().required('Required'),
          otherwise: yup.object().nullable()
        }),
        prorateAddons: yup.bool()
      })
    },
    {
      name: 'Policy Invoicing',
      initialValues: {
        automateInvoiceGeneration: policy?.automateInvoiceGeneration || false,
        billingRule: !!policy?.billingRule
          ? formOptions?.billingRule?.options?.find(option => option.value === policy.billingRule)
          : formOptions?.billingRule?.options[0],
        snapshotCustomDay: policy?.snapshotCustomDay,
        invoiceGenerationDay: policy?.invoiceGenerationDay || '',
        invoicingSchedule:
          formOptions?.invoicingSchedule?.options?.find(o => o.value === policy?.invoicingSchedule) ||
          formOptions?.invoicingSchedule?.options[0],

        pricingIsExternallyFactored: policy?.hasApiFactoredField,
        invoiceDueDay: policy?.invoiceDueDay,
        onePremiumRequired: policy?.onePremiumRequired
      },
      validationSchema: yup.object().shape({
        automateInvoiceGeneration: yup.bool().required('Required'),
        billingRule: yup
          .object()
          .test({
            name: 'billingRuleInvalid',
            message: () => 'Prorating must be disabled',
            test: function test(value) {
              if (isBillingRuleAllowed(this.parent)) return true;
              else return this.createError({ path: this.path, message: billingRuleErrorMessage(this.parent) });
            }
          })
          .required('Billing Rule Required'),
        snapshotCustomDay: yup
          .number()
          .when('billingRule', {
            is: billingRule => billingRule.value === SNAPSHOT_CUSTOM_DAY,
            then: schema => schema.required('Required')
          })
          .min(1, 'Must be greater than 1')
          .max(28, 'Must be less than 28')
          .nullable(),
        invoiceGenerationDay: yup.number().min(1, 'Must be greater than 1').max(28, 'Must be less than 28').nullable(),
        invoiceDueDay: yup.number().min(1, 'Must be greater than 1').max(28, 'Must be less than 28').nullable(),
        onePremiumRequired: yup.bool()
      })
    }
  ];

  return { formSteps };
}
