import React from 'react';
import { Row } from 'reactstrap';
import { Button, useToggle } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { InvoicesGenerateReport } from '../../views';
import { TabPage } from '../../components';

import { usePolicyInvoiceInfo, usePolicyInvoices } from './hooks';
import { InvoiceFilters, PolicyInvoicesTable } from './components';
import { GenerateInvoice } from './containers';
import { HideFromRoles, useActiveAccount } from '../../shared/activeAccount';
import ButtonWithTooltip from '../../components/ButtonWithTooltip';

export default function PolicyInvoices({ policy }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const {
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const [showGenerateReport, toggleGenerateReport] = useToggle();

  const { variables, queryProps, ...policyInvoicesProps } = usePolicyInvoices({
    policyId: policy?.id
  });

  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <TabPage
          title="Invoices"
          buttons={
            <>
              <Button
                color="info"
                outline
                icon={['far', 'file-chart-line']}
                onClick={toggleGenerateReport}
                className="ml-2"
              >
                Generate Report
              </Button>
              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <GenerateInvoiceButton
                  canGenerateOffCycleInvoice={canGenerateOffCycleInvoice}
                  onClick={() => history.push(`${url}/generate`)}
                  disabled={!policy?.effectiveDate}
                />
              </HideFromRoles>
            </>
          }
        >
          <Row noGutters className={'d-flex justify-content-between'}>
            <InvoiceFilters variables={variables} {...policyInvoicesProps} />
          </Row>

          <PolicyInvoicesTable {...policyInvoicesProps} />

          {showGenerateReport && (
            <InvoicesGenerateReport isOpen={showGenerateReport} toggle={toggleGenerateReport} policyId={policy?.id} />
          )}
        </TabPage>
      </Route>
      <Route path={`${path}/generate`}>
        <GenerateInvoice refetchInvoicesArgs={queryProps} policy={policy} />
      </Route>
    </Switch>
  );
}

const GenerateInvoiceButton = ({ disabled, onClick, canGenerateOffCycleInvoice }) => {
  return (
    <>
      <ButtonWithTooltip
        id="generate-invoice-button"
        color="info"
        className="ml-2"
        icon={['far', 'file-invoice-dollar']}
        onClick={onClick}
        disabled={disabled}
        tooltipText={'Cannot generate an invoice if there is no effective date.'}
      >
        {canGenerateOffCycleInvoice ? 'Generate Off Cycle Invoice' : 'Generate Invoice'}
      </ButtonWithTooltip>
    </>
  );
};
