import React from 'react';
import { FieldMessage, InputField, ReactSelectField, Render, SwitchField, useToggle } from '@oforce/global-components';
import { FormSection } from '../../../components';
import { SNAPSHOT_CUSTOM_DAY } from '../../product/utils';
import { Tooltip } from 'reactstrap';

export default function PolicyInvoicingFormFields({ formik, formOptions }) {
  const [showDisabledTooltip, toggleDisabledTooltip] = useToggle();
  const [showScheduleTooltip, toggleScheduleTooltip] = useToggle();
  const [showBackdateTooltip, toggleBackdateTooltip] = useToggle();

  const pricingRateIsWeekly = formik?.values?.pricingRateType?.value === 'WEEKLY';
  const pricingIsExternallyFactored = formik?.values?.pricingIsExternallyFactored;
  const automateInvoiceGeneration = formik?.values?.automateInvoiceGeneration;
  const hasInvoiced = formik?.values?.hasInvoiced;
  const onePremiumRequired = formik?.values?.onePremiumRequired;
  const backdate = formik?.values?.backdate;

  const tooltipMessage = pricingRateIsWeekly
    ? 'Cannot auto generate if pricing rate is weekly.'
    : pricingIsExternallyFactored
    ? 'Not available with api-only factored pricing option.'
    : null;

  return (
    <FormSection title="Policy Invoicing">
      <ReactSelectField
        id="billingRule"
        name="billingRule"
        label="Billing Rule"
        isDisabled={hasInvoiced}
        options={formOptions?.billingRule?.options}
        placeholder="Select Billing Rule..."
        required
      />
      <Render if={formik.values?.billingRule?.value === SNAPSHOT_CUSTOM_DAY}>
        <InputField type="number" name="snapshotCustomDay" label="Snapshot Custom Day" />
      </Render>
      <div id="automatic-invoice-generation-switch-div" className="mb-3">
        <Render if={formik.values?.billingRule?.value !== 'CLASSIC'}>
          <SwitchField
            id="enable-one-premium-required-switch"
            className="mb-3"
            label="One Premium Required"
            name="onePremiumRequired"
            size="sm"
            switchLabel={onePremiumRequired ? 'Enabled' : 'Disabled'}
          />
        </Render>
        <SwitchField
          id="automateInvoiceGeneration"
          label="Automatic Invoice Generation"
          name="automateInvoiceGeneration"
          optional
          // if policy pricing rate type is weekly,
          // or policy is factored externally (meaning the automation of the invoice generation is done outside of IC Insure)
          // then we disable this field in the form
          disabled={pricingRateIsWeekly || pricingIsExternallyFactored}
          size="sm"
          switchLabel={automateInvoiceGeneration ? 'On' : 'Off'}
        />
      </div>
      <Tooltip
        disabled={!tooltipMessage}
        target={'automatic-invoice-generation-switch-div'}
        placement="left-start"
        isOpen={showDisabledTooltip}
        toggle={toggleDisabledTooltip}
      >
        {tooltipMessage}
      </Tooltip>
      <div id="backdate-div">
        <SwitchField
          id="backdate"
          label="Backdate Coverage"
          name="backdate"
          optional
          disabled={hasInvoiced}
          size="sm"
          switchLabel={backdate ? 'On' : 'Off'}
        />
      </div>
      <Tooltip
        disabled={!hasInvoiced}
        target={'backdate-div'}
        placement="left-start"
        isOpen={showBackdateTooltip}
        toggle={toggleBackdateTooltip}
      >
        This setting cannot be changed if policy has already been invoiced.
      </Tooltip>

      <Render if={!pricingRateIsWeekly}>
        <div id="invoicing-schedule-select">
          <ReactSelectField
            className={'mb-0'}
            name="invoicingSchedule"
            label="Collect Payment for"
            required
            isDisabled={hasInvoiced}
            options={formOptions?.invoicingSchedule?.options}
          />
        </div>
        <Tooltip
          disabled={!hasInvoiced}
          target={'invoicing-schedule-select'}
          placement="left-start"
          isOpen={showScheduleTooltip}
          toggle={toggleScheduleTooltip}
        >
          This setting cannot be changed if policy has already been invoiced.
        </Tooltip>
        <FieldMessage className="fs-sm text-muted mb-3">Only applicable when auto generating invoices.</FieldMessage>

        <InputField
          type="number"
          name="invoiceDueDay"
          label="Invoice Due Day"
          messageAfter="Only applicable when auto generating invoices."
        />
        <InputField
          name="invoiceGenerationDay"
          label="Invoice Generation Day"
          optional
          messageAfter="This is the day each month the invoice will generate. Only applicable when auto generating invoices."
        />
      </Render>
    </FormSection>
  );
}
